:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  --primary-color: #21aa95;
  --text-color: @text-color;
  --layout-body-background: #f0f2f5;
  --background-hover: #21aa953d;
  --border-color: #0000001f;
  font-family: 'Roboto', serif;
}

body {
  line-height: 1.35 !important;
  font-family: 'Roboto', serif;
  background-color: #eeeeee;
  font-weight: 600;
  color: var(--text-color);
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0em;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: @font-size-lg;
}

h5 {
  font-size: @font-size-base;
}

h6 {
  font-size: @font-size-base;
}
