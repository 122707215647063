@import '../../../shared/styles/variable';

.main-content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 20px 0;
  min-height: auto;

  @media screen and (min-width: @screen-md) {
    padding: 20px 32px 10px;
  }
}

@primary-color: #21aa95;